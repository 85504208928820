import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getHomeRouteForLoggedInUser, getUserData } from '@/auth/utils'

export default {
  methods: {
    welcomeUser(user = getUserData()) {
      this.$router.replace(getHomeRouteForLoggedInUser('admin'))
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Welcome ${user.full_name}`,
              variant: 'success',
              text: 'You have successfully logged in!',
            },
          })
        })
    },
  },
}
