<template>
  <form-validation-field
    wrapper="b-input-group"
    wrapper-class="input-group-merge"
    :value="value"
    :name="name"
    :rules="rules"
    :type="fieldType"
    :placeholder="placeholder"
    :label="$slots.label ? null : label"
    @input="(val) => $emit('input', val)"
  >
    <template v-slot:append-input>
      <b-input-group-append is-text>
        <feather-icon
          class="cursor-pointer"
          :icon="toggleIcon"
          @click="toggleVisibility"
        />
      </b-input-group-append>
    </template>

    <template #label>
      <slot name="label" />
    </template>
  </form-validation-field>
</template>

<script>
import FormValidationField from '@/components/forms/validation/FormValidationField.vue'
import { BInputGroupAppend } from 'bootstrap-vue'

export default {
  components: {
    BInputGroupAppend,
    FormValidationField,
  },
  props: {
    name: {
      type: String,
      default: 'password',
    },
    label: {
      type: String,
      default: 'Password',
    },
    rules: {
      type: String,
      default: 'required|min:4',
    },
    placeholder: {
      type: String,
      default: '············',
    },
    value: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      fieldType: 'password',
    }
  },
  computed: {
    toggleIcon() {
      return this.fieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    toggleVisibility() {
      this.fieldType = this.fieldType === 'password' ? 'text' : 'password'
    },
  },
}
</script>
