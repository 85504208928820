<template>
  <auth-card
    ref="authCard"
    :loading="loading"
    @submit="register"
  >

    <template #title>
      Sign up
    </template>

    <template #form>

      <!-- first name -->
      <form-validation-field
        ref="firstName"
        v-model="form.first_name"
        name="first-name"
        rules="required"
        label="First Name"
        autofocus
      />

      <!-- last name -->
      <form-validation-field
        ref="lastName"
        v-model="form.last_name"
        name="last-name"
        rules="required"
        label="Last Name"
      />

      <!-- email -->
      <form-validation-field
        ref="emailField"
        v-model="form.email"
        name="email"
        rules="required|email"
        label="Email"
        type="email"
        placeholder="john@example.com"
      />

      <!-- password -->
      <form-validation-password-input v-model="form.password" />

      <!-- privacy policy -->
      <b-form-group>
        <validation-provider
          #default="{ errors }"
          name="privacy policy"
          :rules="{ required: { allowFalse: false } }"
        >
          <b-form-checkbox
            id="register-privacy-policy"
            v-model="privacyPolicy"
            name="privacy-policy"
            :state="errors.length > 0 ? false:null"
          >
            I agree to
            <b-link>privacy policy & terms</b-link>
          </b-form-checkbox>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

    </template>

    <template #button-text>
      Register
    </template>

    <template #footer>
      <span>Already have an account? </span>
      <b-link :to="{ name:'auth-login' }">
        <span>Sign in instead</span>
      </b-link>
    </template>

  </auth-card>
</template>

<script>
import {
  BFormCheckbox,
  BFormGroup,
  BLink,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { email as validateEmail, required } from '@validations'
import AuthMixin from '@/mixins/auth'
import useJwt from '@/auth/jwt/useJwt'
import FormValidationField from '@/components/forms/validation/FormValidationField.vue'
import FormValidationPasswordInput from '@/components/forms/validation/FormValidationPasswordInput.vue'
import AuthCard from '@/components/auth/AuthCard.vue'

export default {
  components: {
    AuthCard,
    BLink,
    BFormGroup,
    BFormCheckbox,
    FormValidationField,
    FormValidationPasswordInput,
    ValidationProvider,
  },
  mixins: [AuthMixin],
  data() {
    return {
      form: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
      },
      privacyPolicy: '',
      loading: false,
      // validation rules
      required,
      validateEmail,
    }
  },
  methods: {
    register() {
      this.loading = true

      useJwt.register({ ...this.form, password_confirmation: this.form.password })
        .then(() => {
          useJwt.login(true, { email: this.form.email, password: this.form.password })
            .then(() => this.welcomeUser())
        })
        .catch(error => {
          this.$refs.authCard.$refs.form.setErrors(error.response.data.errors)
          this.loading = false
        })
    },
  },
}
</script>
